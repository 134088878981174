/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ff94dbb9-bdce-4df0-b05d-4bae67217c5e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JJCalsEtC",
    "aws_user_pools_web_client_id": "7m8jc2vmpubb6er842lfuo9jr9",
    "oauth": {
        "domain": "automatic-pease-two-test.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 12,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_user_files_s3_bucket": "automatic-nj-scarletknights-test",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "peasefe-20240723094347-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dnpgu76c6uhp5.cloudfront.net"
};


export default awsmobile;
